import { ContainerMaxWidth, Stack } from '@hypersay/ui';
import { withPrefix } from 'gatsby';
import React from 'react';

import { InlineWidget } from 'react-calendly';
import SEO from '../components/seo';
import Layout from '../layouts/default';

import { Link } from 'gatsby-plugin-intl';
import * as styles from './live-presentations.module.scss';

const Webinars = () => (
  <Layout>
    <SEO
      title="Webinare în limba română"
      description="Webinare gratuite de utilizare a platformei Hypersay în educație."
      keywords={[
        'webinar',
        'free webinar',
        'remote learning',
        'remote teaching',
        'remote working',
        'work from home',
        'wfh',
        'video streaming',
        'video calling',
        'live presentation',
        'interactive presentations',
        'live polling',
        'live quiz',
      ]}
      shareImage="share-image-webinars-ro.jpg"
    />
    <ContainerMaxWidth className={styles.container} maxWidth="1400px">
      <div className={styles.highlightContainer}>
        <Stack className={`${styles.highlightColumn}`}>
          <img
            src={withPrefix('/images/MicrophoneColors.svg')}
            alt="interactions"
            style={{ width: '150px' }}
          />
          <div className={styles.betaTitle}>
            <h2>Webinare în limba română</h2>
          </div>
          <p>
            Vă puteți înscrie mai jos la webinarele noastre gratuite de
            prezentare a platformei Hypersay.
          </p>
          <Link to="/webinars">
            Pentru webinare în limba engleză click aici
          </Link>
          <InlineWidget
            styles={{
              height: '1000px',
              width: '100%',
            }}
            url="https://calendly.com/paul-balogh/cum-sa-tii-un-curs-online"
          />
        </Stack>
      </div>
    </ContainerMaxWidth>
  </Layout>
);

export default Webinars;
